import React, { useEffect, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { AppContext, App } from '../components/app';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';
import Copy2Cliboard from '../util/copy2clipboard';

const ContactPage = ({ location }) => {
    const context = useContext(AppContext);
    const { state, dispatch } = context;

    useEffect(() => {
        if (!state || !state.affiliateId) {
            navigate('/login');
        }
    }, []);

    const copyToClipboard = e => {
        Copy2Cliboard.select_all_and_copy(e.target);
    };

    if (!state || !state.affiliateId) {
        return <div></div>;
    }
    return (
        <Layout>
            <Menu location={location} />

            <h1 className="huge nohyphens">Picture of the&nbsp;day</h1>
            <p className="padding-bottom-0">
                POTD changes automatically so you don't have to&nbsp;do anything
                and your website stays updated. Simply copy and paste the code
                into your website. Or you can only use the landing page.
            </p>
            <div className="spacer line spacer-80">&nbsp;</div>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens padding-bottom-0">Latest update</h1>
            <div className="spacer spacer-80">&nbsp;</div>

            <div className="forms">
                <div className="forms-fix">
                    <form>
                        <div className="noborder">
                            <div className="panel">
                                <img
                                    src="https://u2n7z7k4.ssl.hwcdn.net/latest-issue-800.jpg"
                                    alt="W4B"
                                />
                            </div>
                            <div className="spacer spacer-16">&nbsp;</div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="potd001"
                                    className="has-value"
                                    rows="2"
                                    onFocus={copyToClipboard}
                                    value={`http://fhg.bcash4you.com/w4b/potd/latest-issue.html?${state.affiliateId}`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="potd001"
                                >
                                    POTD 001 landing page
                                </label>
                            </div>
                        </div>
                        <div className="button-container">
                            <a
                                href={`http://fhg.bcash4you.com/w4b/potd/latest-issue.html?${state.affiliateId}`}
                                className="button full"
                                target="_blank"
                                title="View"
                            >
                                View
                            </a>
                        </div>
                        <div className="spacer spacer-16">&nbsp;</div>
                        <div className="noborder">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="code001"
                                    className="has-value"
                                    onFocus={copyToClipboard}
                                    value={`<a href="http://fhg.bcash4you.com/w4b/potd/latest-issue.html?${state.affiliateId}" title="Watch4beauty"><img src="http://www.watch4beauty.com/latest-issue-400.jpg" border="0" alt="Watch4beauty" /></a>`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="code001"
                                >
                                    POTD 001 HTML code
                                </label>
                            </div>
                        </div>
                        <div className="noborder">
                            <p
                                className="mini"
                                style={{ 'textAlign': 'left' }}
                            >
                                Available POTD image sizes:
                                <br />
                                <a
                                    href="http://www.watch4beauty.com/latest-issue-100.jpg"
                                    className="underline"
                                >
                                    100x133
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/latest-issue-150.jpg"
                                    className="underline"
                                >
                                    150x200
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/latest-issue-200.jpg"
                                    className="underline"
                                >
                                    200x267
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/latest-issue-400.jpg"
                                    className="underline"
                                >
                                    400x533
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/latest-issue-800.jpg"
                                    className="underline"
                                >
                                    800x1067
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/latest-issue-1600.jpg"
                                    className="underline"
                                >
                                    1600x2133
                                </a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
            <div className="spacer line spacer-80">&nbsp;</div>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens padding-bottom-0">Random photo</h1>
            <div className="spacer spacer-80">&nbsp;</div>

            <div className="forms">
                <div className="forms-fix">
                    <form>
                        <div className="noborder">
                            <div className="panel">
                                <img
                                    src="https://u2n7z7k4.ssl.hwcdn.net/potd-800.jpg"
                                    alt="W4B"
                                />
                            </div>
                            <div className="spacer spacer-16">&nbsp;</div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="potd002"
                                    className="has-value"
                                    rows="1"
                                    onFocus={copyToClipboard}
                                    value={`http://fhg.bcash4you.com/w4b/potd/potd.html?${state.affiliateId}`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="potd002"
                                >
                                    POTD 002 landing page
                                </label>
                            </div>
                        </div>
                        <div className="button-container">
                            <a
                                href={`http://fhg.bcash4you.com/w4b/potd/potd.html?${state.affiliateId}`}
                                className="button full"
                                target="_blank"
                                title="View"
                            >
                                View
                            </a>
                        </div>
                        <div className="spacer spacer-16">&nbsp;</div>
                        <div className="noborder">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="code002"
                                    className="has-value"
                                    rows="4"
                                    onFocus={copyToClipboard}
                                    value={`<a href="http://fhg.bcash4you.com/w4b/potd/potd.html?${state.affiliateId}" title="Watch4beauty"><img src="http://www.watch4beauty.com/potd-400.jpg" border="0" alt="Watch4beauty" /></a>`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="code002"
                                >
                                    POTD 002 HTML code
                                </label>
                            </div>
                        </div>
                        <div className="noborder">
                            <p
                                className="mini"
                                style={{ 'textAlign': 'left' }}
                            >
                                Available POTD image sizes:
                                <br />
                                <a
                                    href="http://www.watch4beauty.com/potd-100.jpg"
                                    className="underline"
                                >
                                    100x133
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/potd-150.jpg"
                                    className="underline"
                                >
                                    150x200
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/potd-200.jpg"
                                    className="underline"
                                >
                                    200x267
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/potd-400.jpg"
                                    className="underline"
                                >
                                    400x533
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/potd-800.jpg"
                                    className="underline"
                                >
                                    800x1067
                                </a>
                                ,{' '}
                                <a
                                    href="http://www.watch4beauty.com/potd-1600.jpg"
                                    className="underline"
                                >
                                    1600x2133
                                </a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>

            <div className="spacer spacer-80">&nbsp;</div>
            <Footer />
        </Layout>
    );
};

export default ContactPage;
